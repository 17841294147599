import React from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/footer/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Components/Main/Home/Home";
import AboutUs from "./Components/Main/AboutUs/AboutUs";
import Services from "./Components/Main/Services/Services";
import WhyChooseUs from "./Components/Main/WhyChooseUs/WhyChooseUs";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/whyUs" element={<WhyChooseUs />} />
        {/* <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:id" element={<Post />} /> */}
        {/* </Route> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
// import "./Header.css";

import Navbar from "./Navbar/Navbar";

function Header() {
  return (
    <header>
      <Navbar />
    </header>
  );
}

export default Header;

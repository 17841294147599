import React, { useState, useEffect } from "react";
import { allServicesData } from "./ServiceData.js";
import "./Services.css";
import ContactFormShort from "../../Shared/ContactFormShort/ContactFormShort.js";
import { Link } from "react-router-dom";
import ServicesComponent from "./ServicesItem.js";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function Services() {
  const [activeDiv, setActiveDiv] = useState(0);
  // const [serviceData, selectServiceData] = useState(allServicesData[0]);

  const handleDivChange = (index) => {
    setActiveDiv(index);
  };

  const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div>
        <div
          onClick={toggleAccordion}
          className={
            isOpen
              ? "services-accordion-header active-header"
              : "services-accordion-header"
          }
        >
          <span className="accordion-header">{title}</span>
          <span className="accordion-icon">
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </div>
        {isOpen && <div>{children}</div>}
      </div>
    );
  };

  const [isWide, setIsWide] = useState(window.innerWidth >= 1125);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 1125);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section>
      <div className="services-container">
        <div className="services-banner">
          <div className="services-bannerLeft">
            <p>
              <Link to="/">Home</Link> / Services
            </p>
            <h1>Services</h1>
          </div>
          <div className="services-bannerRight">
            <p>
              Technology is driving businesses forward. And Klug is all about
              providing IT solutions to ensure that. We go beyond software
              development, building long-lasting partnerships that help you
              continue growing. Our IT services drive innovation and growth,
              ensuring you are always one step ahead in this digital age.
            </p>
          </div>
        </div>
        <div>
          {isWide ? (
            <div className="services-info">
              <div className="services-infoLeft">
                <h2>
                  <span className="services-infoLeft-header">
                    Software development services
                  </span>
                  <br /> for all your business needs
                </h2>
                <div className="service-names">
                  {allServicesData.map((service, i) => (
                    <div
                      onClick={() => handleDivChange(i)}
                      className={
                        activeDiv === i ? "activeService service" : "service"
                      }
                    >
                      {service.header}
                    </div>
                  ))}
                </div>
              </div>
              {<ServicesComponent data={allServicesData[activeDiv]} />}
            </div>
          ) : (
            <div className="services-info">
              <div className="services-infoLeft">
                <h2>
                  <span>Software development services</span>
                  <br /> for all your business needs
                </h2>
                <div className="service-names">
                  {allServicesData.map((service, i) => (
                    <AccordionItem title={service.header}>
                      <ServicesComponent data={service} />
                    </AccordionItem>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <ContactFormShort></ContactFormShort>
      </div>
    </section>
  );
}

export default Services;

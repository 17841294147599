const webApplicationsData = {
  header: "Software Engineering Services",
  description: [
    "Software solutions redefine the way your business operates. You can achieve more with the right software solutions. We provide software solutions that drive your business to be more competitive. We excel in removing any technological barriers holding you back. Whether web applications or mobile apps, we've got you all covered.",

    " Our solutions are fast, secure, and adaptable. Our solutions are fast, secure, flexible, highly scalable, and compatible. They run smoothly on every platform.",
  ],
  boxes: [
    {
      header: "One-Stop Solution",
      description:
        "We manage every phase of your development cycle, tailored to your needs. With everything in one place, you get smooth and efficient results. ",
    },
    {
      header: "E-Commerce Solutions",
      description:
        "We create feature-rich, robust, scalable e-commerce platforms that enhance user experience and drive sales.",
    },
    {
      header: "Technology Stack Expertise",
      description:
        "We leverage React and React Native to develop fast, responsive, dynamic web applications.",
    },
    {
      header: "Software Consultation",
      description:
        "We offer free software consulting services to help you choose suitable technologies and strategies for your business. ",
    },
  ],
};

const apiCommunicationData = {
  header: "API Communication Services",
  description: [
    "APIs make communication easier for software ecosystems. They enable smooth interaction between systems and applications. Effective API communication optimizes workflows, improves efficiency, and supports seamless integrations. At Klug, we specialize in creating and managing APIs that provide secure and efficient data exchange, ensuring your systems operate more cohesively.",
    "We ensure that APIs are scalable and strategic, adapting to the growing needs of your business. We are experts in connecting your software to external services. It enhances functionality and provides better user experiences.",
  ],
  boxes: [
    {
      header: "API Development",
      description:
        "We design and develop custom APIs for you. You get seamless integration and communication between different systems.",
    },
    {
      header: "Third-Party API Integration",
      description:
        "Our team excels in integrating third-party APIs. These APIs enable business workflows to leverage external services and functionalities effectively.",
    },
    {
      header: "RESTful and GraphQL Proficiency",
      description:
        "Our proficiency in RESTful and GraphQL APIs provides flexible and efficient communication solutions.",
    },
    {
      header: "Enhanced Compatibility",
      description:
        "Our API solutions improve the compatibility of complex systems. They allow easy data sharing and better coordination between different parts of software.",
    },
  ],
};

const integrationEngineeringData = {
  header: "Power BI Services",
  description: [
    "We use Power BI to help our clients make data-driven decisions. We provide you with the clarity and foresight you need to stay competitive. You get intuitive, interactive, customized Power BI solutions to grow your business. ",
    "Klug focuses on creating complete dashboards and reports that help in future decisions. We provide real-time insights, allowing you to observe critical metrics and trends to stay two steps ahead. We transform your data into valuable information, rather than just storing it in a database. With our expertise in Power BI, we ensure that you use this data to drive business growth and efficiency.",
  ],
  boxes: [
    {
      header: " Dashboards and Reports",
      description:
        "We design and develop customized Power BI dashboards and reports.  These curated reports help you cut the technical jargon and have access to the most relevant data.",
    },
    {
      header: "Data Integration",
      description:
        "Our team integrates data from various sources like SQL servers, Azure, AWS, and more into Power BI. Then, we give you a unified view of your business performance.",
    },
    {
      header: "Advanced Analytics",
      description:
        "We utilize Power BI's advanced analytics capabilities like data forecasting, machine learning integration to provide deeper insights and predictive analysis.",
    },
    {
      header: "User Training and Support",
      description:
        "We take pride in offering comprehensive training and support to ensure your team can effectively use Power BI.",
    },
  ],
};

const architectureDevelopmentData = {
  header: "System Architecture & Design Services",
  description: [
    "Developing great systems starts here. Klug puts massive effort into making ideal decisions on interfaces, technologies, components, etc. It helps seamlessly integrate new software and technologies into your business.",
    "We start by understanding the core of your business and designing a future-proof system. We focus on creating flexible and scalable solutions that facilitate seamless integration, improved performance, and long-term sustainability.",
  ],
  boxes: [
    {
      header: "System Analysis and Design",
      description:
        "We conduct a detailed analysis of your existing systems and business requirements to design a tailored architecture.",
    },
    {
      header: "Component-Based Architecture",
      description:
        "We always prefer using a component-based architecture. It helps break down your system into manageable and reusable modules.",
    },
    {
      header: "Advanced Tech Utilization",
      description:
        "We use the latest tech, languages, and frameworks like Docker, Kubernetes, Python, microservices architecture, and more. It makes the system modern, efficient, and industry-based. ",
    },
    {
      header: "Future-Ready Solutions",
      description:
        "All that R&D before designing a system helps us to develop future-proof architectural designs. You can easily update and integrate your newly developed system, ensuring it evolves with your business and technological advancements.",
    },
  ],
};

const uiuxDesigningData = {
  header: "UI/UX Services",
  description: [
    "We deliver innovative and distinctive designs. We bring your vision to life. We also expertly redesign old systems and tailor them to your vision. We offer interactive prototypes, usability testing, user journey mapping, and more services. We take our time to thoroughly research every aspect to ensure that your final product perfectly represents you at a glance. In short, Klug gives you designs with a professional and creative touch.",
  ],
  boxes: [
    {
      header: "App Design",
      description:
        "We design minimalistic, lavish, fresh, responsive desktop and mobile apps. These apps are highly secure with a scalable architecture.",
    },
    {
      header: "Web Interfaces",
      description:
        "We excel at designing user-friendly, visually appealing, and feature-rich web interfaces. ",
    },
    {
      header: "Modern Tools Expertise",
      description:
        "We utilize tools like Figma, Sketch, Adobe XD, etc., for collaborative and efficient design processes.",
    },
    {
      header: "Brand Identity",
      description:
        "Every design tells a story. Our designs ensure your product reflects your unique brand identity and values.",
    },
  ],
};

const legacySystemUpgradeData = {
  header: "Legacy System Upgrade Services",
  description: [
    "Upgrading legacy systems helps maintain efficiency, stay relevant, and remain competitive. Outdated systems often fail to meet modern demands, resulting in inefficiency and higher maintenance costs. Klug specializes in modernizing these systems. We ensure legacy systems align with existing technologies and business requirements. We help our partners minimize costs through automation.",
    "We upgrade systems while keeping them seamless and minimally disruptive. It allows your business to keep operating smoothly during this transition. We prioritize enhancing security, performance, and scalability to support your future growth and provide a long-term solution.",
  ],
  boxes: [
    {
      header: "System Optimization",
      description:
        "We evaluate existing systems to identify areas for improvement and develop a tailored upgrade plan.",
    },
    {
      header: "Next-Gen Modernization",
      description:
        "We modernize legacy systems with the latest but relevant technologies like AWS, Azure, Spring Boot, Kafka, and more. It enhances performance, security, and user experience.",
    },
    {
      header: "Efficient Data Migration",
      description:
        "We ensure a smooth and secure migration of your data, maintaining data integrity and minimizing downtime.",
    },
    {
      header: "Scalable Solutions",
      description:
        "We design our upgrades to grow with your business, ensuring your system remains relevant and capable of supporting future advancements.",
    },
  ],
};
export const allServicesData = [
  webApplicationsData,
  apiCommunicationData,
  integrationEngineeringData,
  architectureDevelopmentData,
  uiuxDesigningData,
  legacySystemUpgradeData,
];

import React, { useEffect, useState } from "react";
import "./AboutUs.css";
import aboutUsProcess from "../../../Assets/Images/aboutUsProcess.svg";
import aboutUsProcessPhone from "../../../Assets/Images/aboutUsProcessPhone.png";
import IconPhilosophy1 from "../../../Assets/Images/IconPhilosophy1.png";
import IconPhilosophy2 from "../../../Assets/Images/IconPhilosophy2.png";
import IconPhilosophy3 from "../../../Assets/Images/IconPhilosophy3.png";
import IconPhilosophy4 from "../../../Assets/Images/IconPhilosophy4.png";
import ContactFormShort from "../../Shared/ContactFormShort/ContactFormShort";
import { Link } from "react-router-dom";

function AboutUs() {
  const handleScroll = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  const [isWide, setIsWide] = useState(window.innerWidth >= 1125);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 1125);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const aboutUsProcessImgSrc = isWide ? aboutUsProcess : aboutUsProcessPhone;
  return (
    <section>
      <div className="aboutus-container">
        <div className="aboutus-banner">
          <div className="aboutus-bannerLeft">
            <p>
              <Link to="/">Home</Link> / About Us
            </p>
            <h1>About Us</h1>
          </div>
          <div className="aboutus-bannerRight">
            <p>
              At Klug, we help your business to grow and succeed. We excel in
              making systems adaptive and customized as required. We build
              long-lasting relationships through our commitment, agility, and
              transparency. Whether you are a new startup or an enterprise
              ruling your field, we treat all as our partners, with one goal:
              Making your dreams come true.
            </p>
          </div>
        </div>
        <div className="aboutus-talktous">
          <div className="gridLeft">
            <h2>More Than Projects, We Build Trust</h2>
            <button className="primaryBtn" onClick={handleScroll}>
              Talk To Us
            </button>
          </div>
          <div className="gridRight">
            <h5>Committed to Your Long-Term Success</h5>
            <p>
              Our commitment goes beyond delivering outstanding projects. At
              Klug, we aim for your long-term success. We believe in building
              relationships that last, ensuring our collaboration drives your
              business forward, well into the future.
            </p>
          </div>
          <div className="talktous-btnresponsive">
            <button className="primaryBtn" onClick={handleScroll}>
              Talk To Us
            </button>
          </div>
        </div>
        <div className="aboutus-process">
          <div className="sectionTitle">
            <h2>
              <span>Process-Driven</span> Solutions for Lasting Success{" "}
            </h2>
          </div>
          <div className="sectionImg">
            <img alt="process" src={aboutUsProcessImgSrc}></img>
          </div>
        </div>
        <div className="aboutus-philosophy">
          <div className="sectionTitle">
            <div id="headerText">
              {isWide ? (
                <h2>
                  Our Working<br></br> <span>Philosophy</span>
                </h2>
              ) : (
                <h2>
                  Our Working <span>Philosophy</span>
                </h2>
              )}
            </div>
            <div id="paraText">
              <p>
                At Klug, we ensure transparent communication, so you are always
                kept informed and involved. We uphold integrity and
                accountability, striving to understand your unique needs. This
                dedication drives us to deliver quality work that meets and
                exceeds your expectations. We work swiftly while maintaining a
                structured approach.{" "}
              </p>
            </div>
          </div>
          <div className="sectionContent">
            <div className="philosophy1 bgcolordark">
              <img alt="icon" src={IconPhilosophy1}></img>
              <h5>Transparency</h5>
              <p>
                We believe in keeping you informed throughout our process. We
                ensure transparent communication, enabling you to provide active
                feedback throughout the process.
              </p>
            </div>
            <div className="philosophy2 bgcolordark">
              <img alt="icon" src={IconPhilosophy2}></img>
              <h5>Integrity and Accountability</h5>
              <p>
                We hold ourselves to high work ethics, taking ownership and
                delivering results as promised. This ensure that we consistently
                meet your expectations with transparency and trust.
              </p>
            </div>
            <div className="philosophy3 bgcolordark">
              <img alt="icon" src={IconPhilosophy3}></img>
              <h5>Understanding Needs</h5>
              <p>
                We listen actively to understand your specific requirements.
                Then, we tailor our approach to meet your needs effectively. At
                Klug, ethical business practices form the foundation of a strong
                business partnership.
              </p>
            </div>
            <div className="philosophy4 bgcolordark">
              <img alt="icon" src={IconPhilosophy4}></img>
              <h5>Quality Work</h5>
              <p>
                Our team pays attention to every detail to ensure quality
                results. We consistently monitor our work to maintain high
                standards and keep improving.
              </p>
            </div>
          </div>
        </div>
        <ContactFormShort></ContactFormShort>
      </div>
    </section>
  );
}

export default AboutUs;

import React from "react";
import "./Services.css";

const ServicesItem = ({ data }) => {
  const serviceElement = [];
  const ParaList = [];
  const boxesList = [];

  for (const para of data.description) {
    ParaList.push(<p>{para}</p>);
  }

  for (const box of data.boxes) {
    boxesList.push(
      <div className="infoRightGrid">
        <h5 className="infoRightBoxesHeader">{box.header}</h5>
        <p className="infoRightBoxesText">{box.description}</p>
      </div>
    );
  }
  serviceElement.push(
    <div className="services-infoRight">
      <div className="infoRightDiv">
        <h5>{data.header}</h5>
        <p>{ParaList}</p>
        <div className="infoRightBoxes">{boxesList}</div>
      </div>
    </div>
  );
  return <div>{serviceElement}</div>;
};

export default ServicesItem;
